var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass:
        "payment-type payment-type-nexi ticket-type-restaurant px-0 px-sm-auto"
    },
    [
      _c("span", {
        staticClass: "descr",
        domProps: { innerHTML: _vm._s(_vm.paymentType.descr) }
      }),
      _c("ResponseMessage", { attrs: { response: _vm.response } }),
      !_vm.ticketData.logged
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-start mt-5" },
            [
              _vm.isAuthenticated && _vm.paymentType.paymentTypeId == 21
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        width: _vm.$vuetify.breakpoint.md ? "40%" : "auto"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: [
                                          "ml-2 edenred-button",
                                          {
                                            "white-background":
                                              _vm.paymentType.paymentTypeId ==
                                              21
                                          }
                                        ],
                                        attrs: { depressed: "" },
                                        on: { click: _vm.login }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-img", {
                                      staticStyle: { width: "140px" },
                                      attrs: {
                                        src:
                                          "/img_layout/meal_ticket/btn_" +
                                          _vm.paymentType.paymentTypeId +
                                          ".svg",
                                        alt: "Logo Ticket Restaurant"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        393720182
                      ),
                      model: {
                        value: _vm.dialog,
                        callback: function($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog"
                      }
                    },
                    [
                      _vm.dialog
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column pellegrini-container"
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-img", {
                                    staticClass: "pellegrini-logo",
                                    attrs: {
                                      src:
                                        "/img_layout/meal_ticket/21_login.svg",
                                      alt: "Esegui il login Pellegrini"
                                    }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "close modal-close-button-ticket",
                                      attrs: {
                                        icon: "",
                                        "aria-label": _vm.$t("common.close")
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("$close")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "6" } }, [
                                        _c(
                                          "h4",
                                          {
                                            staticStyle: {
                                              "font-size": "25px",
                                              color: "#9e0005",
                                              "font-weight": "500",
                                              "text-transform": "none"
                                            }
                                          },
                                          [_vm._v(" Login ")]
                                        )
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "logo-col",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass:
                                              "logo-extended justify-end",
                                            attrs: {
                                              src: "/logo/logo-extended.svg",
                                              alt: "Esegui il login Pellegrini"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.loginPellegrini.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "elevation-0 px-0 px-sm-7 pb-5"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-h1 line-height-1 text-center mt-6 mb-2",
                                              staticStyle: {
                                                color:
                                                  "#555555 text-transform: none"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Accedi inserendo le credenziali che utilizzi per il portale Pellegrini® "
                                              )
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-2",
                                                attrs: {
                                                  color: "primary",
                                                  "error-messages": _vm.errors,
                                                  type: "text",
                                                  label: "Username",
                                                  autocomplete: "off",
                                                  required: "",
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.username,
                                                  callback: function($$v) {
                                                    _vm.username = $$v
                                                  },
                                                  expression: "username"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  color: "primary",
                                                  label: "Password",
                                                  autocomplete: "off",
                                                  "append-icon": _vm.showPassword
                                                    ? "$eye"
                                                    : "$eyeOff",
                                                  type: _vm.showPassword
                                                    ? "text"
                                                    : "password",
                                                  rules: _vm.passwordRules,
                                                  required: "",
                                                  dense: "",
                                                  outlined: "",
                                                  clearable: ""
                                                },
                                                on: {
                                                  "click:append":
                                                    _vm.toggleShowPassword
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password"
                                                }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column remember-password"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "white--text",
                                                      attrs: {
                                                        loading:
                                                          _vm.loadingPellegrini,
                                                        type: "submit",
                                                        color: "#9e0005",
                                                        large: "",
                                                        block: "",
                                                        depressed: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "login.buttonLogin"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("ResponseMessage", {
                                                    staticClass: "mt-3",
                                                    attrs: {
                                                      response: _vm.response
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 edenred-button",
                      attrs: { depressed: "" },
                      on: { click: _vm.login }
                    },
                    [
                      _c("v-img", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          src:
                            "/img_layout/meal_ticket/btn_" +
                            _vm.paymentType.paymentTypeId +
                            ".svg",
                          alt: "Logo Ticket Restaurant"
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _c("v-row", { staticClass: "d-flex justify-center mt-5" }, [
        _vm.ticketData.logged || _vm.ticketData.giftCertificateConfirmed == true
          ? _c("div", { staticClass: "authenticated" }, [
              _vm.ticketData.logged
                ? _c("div", { staticClass: "logout-panel row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 img" },
                      [
                        _c("v-img", {
                          staticClass: "logo-heading-image",
                          attrs: {
                            src:
                              "/img_layout/meal_ticket/" +
                              _vm.paymentType.paymentTypeId +
                              ".svg",
                            alt: "Logo" + _vm.paymentType.paymentName
                          }
                        })
                      ],
                      1
                    ),
                    _vm.ticketData.giftCertificateConfirmed == false
                      ? _c("div", { staticClass: "col-6" }, [
                          _vm._v(
                            " Accesso a " +
                              _vm._s(
                                _vm.ticketData.paymentType.paymentTypeId == 21
                                  ? "Pellegrini"
                                  : "Edenred"
                              ) +
                              " effettuato "
                          ),
                          _c("br"),
                          _c(
                            "a",
                            {
                              staticClass: "logout-button clickable",
                              attrs: {
                                title: "Disconnetti il tuo account",
                                "aria-label": "Disconnetti il tuo account"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.logout()
                                }
                              }
                            },
                            [_vm._v(" ESCI ")]
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("div", { staticClass: "max-payable justify-start" }, [
                _vm._v(" Puoi pagare fino a "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.$n(_vm.ticketData.maxAmount, "currency")))
                ]),
                _vm._v(
                  " della tua spesa con " +
                    _vm._s(
                      _vm.$t(
                        "orders.ticketType." +
                          _vm.ticketData.paymentType.paymentTypeId
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "div",
                { staticClass: "ticket-data-wrapper" },
                [
                  _c("div", { staticClass: "row header" }, [
                    _c("div", { staticClass: "col-2" }, [_vm._v("BUONO")]),
                    _c("div", { staticClass: "col-8" }, [_vm._v("QUANTITÀ")]),
                    _c("div", { staticClass: "col-2" }, [_vm._v("TOTALE")])
                  ]),
                  _vm._l(_vm.vouchers, function(voucher, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(_vm.$n(voucher.value, "currency")))
                      ]),
                      _c("div", { staticClass: "col-8" }, [
                        _c("div", { staticClass: "qty-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "decrement-button",
                              attrs: {
                                disabled:
                                  voucher.currentCount == 0 ||
                                  _vm.ticketData.giftCertificateConfirmed ==
                                    true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.decrement(voucher)
                                }
                              }
                            },
                            [_vm._v(" - ")]
                          ),
                          _c("span", { staticClass: "qty" }, [
                            _vm._v(_vm._s(voucher.currentCount))
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "increment-button",
                              attrs: {
                                disabled:
                                  voucher.currentCount == voucher.maxCount ||
                                  _vm.ticketData.giftCertificateConfirmed ==
                                    true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.increment(voucher)
                                }
                              }
                            },
                            [_vm._v(" + ")]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$n(
                                voucher.value * voucher.currentCount,
                                "currency"
                              )
                            ) +
                            " "
                        )
                      ])
                    ])
                  }),
                  _c("div", { staticClass: "row footer-ticket" }, [
                    _c("div", { staticClass: "col-2" }, [_vm._v("Totale")]),
                    _c("div", { staticClass: "col-8" }),
                    _c("div", { staticClass: "col-2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$n(_vm.grossTotal, "currency")) + " "
                      )
                    ])
                  ])
                ],
                2
              ),
              _c("div", { staticClass: "total" })
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }